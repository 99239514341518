import Vue from 'vue'
import { IBXService } from '@services/ibx/IBXService'
import { StoreOptions } from 'vuex'
import { IItemData, ItemAuthor, ItemPassageAff, ItemPassageAffAction } from '@/components/ibx/base/Item'
import { Id, Bank, ItemConfigs, ItemTypes } from '@/components/ibx/base/Types'
import { EventBus, Events } from '@/events'
import { IPassageData } from '@/components/ibx/base/Passage'
import { FLAG } from '@constants'
import _ from 'lodash'

/**
 * Item Authoring modes
 */
export enum ItemAuthoringMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum ItemAuthorSaveAction {
  DRAFT = 'draft',
  PUBLISH = 'publish',
  PUBLISH_NEW = 'publish-new',
  DISCARD = 'discard',
}

/**
 * Item Update paramaters object
 */
export type ItemUpdate = {
  itemId: Id // Item to update
  key: string // Property to update
  value: any // Propery value to update,
  save?: boolean // Remote save flag. If false or empty only save in client
}

export type ItemMutated = {
  itemId: Id
  itemRevId: Id
}

export type ItemAction = {
  itemId: Id
  action: string
}

/**
 * Item menu tabs
 */
export enum MenuTab {
  STANDARDS,
  PASSAGE,
  TAGS,
}

export interface IStateData {
  initialized: boolean
  items: ItemAuthor[]
  banks: Bank[]
  autosave: boolean
}

export interface IStateUI {
  selectedBanks: Id[]
  menuTab: MenuTab | null
  menuTabView: String | null
  saving: Id[] // array of entities saving
  changed: Id[] // array of entities that changed
  loading: boolean
  passageAffSaving: boolean
  active: boolean
  maxItems: number
}

export class State implements IStateData, IStateUI {
  initialized: boolean = false
  items: ItemAuthor[] = []
  newItemMap: any = {}
  banks: Bank[] = null
  selectedBanks: Id[] = []
  saving: Id[] = []
  changed: Id[] = []
  autosave: boolean = true
  mode: ItemAuthoringMode = ItemAuthoringMode.CREATE
  menuTab: MenuTab | null = null
  menuTabView: String | null = null
  loading: boolean = false
  ogItemRevIdMap: { [key: number]: any } = {} // itemId, itemRevId
  itemsMutated: { [key: number]: any } = {} // itemId, itemRevId
  itemActions: { [key: number]: string[] } = {} // itemId, actions[]
  itemErrors: { [key: number]: any } = {} // itemId, error
  passageAffSaving: boolean = false
  active: boolean = false
  maxItems: number = 15
  banksChanged: boolean = false
  saveAction: ItemAuthorSaveAction = null
  inheritPassage: boolean = true
  auxiliaryActions: { [key: string]: any } = {}
  itemRevUpdateOption: string = sessionStorage?.getItem('asmt-item-rev-update-option') || 'all'
}

let configs: ItemConfigs
let types: ItemTypes
let _uniqueId: number = 0

/**
 * Item uniqueId helper (for clientId)
 */
const uniqueId = (): string => {
  _uniqueId++
  return `new_item_${_uniqueId}`
}

/**
 * Find item helper
 * @param itemId
 * @param items
 */
const findItem = (itemId: Id, items: ItemAuthor[] = []): ItemAuthor => {
  return items.find((o: ItemAuthor) => o.itemId == itemId) || null
}

/**
 * Has savable mutation helper
 * @param mutations
 */
const saveMutation = (mutations: ItemUpdate[] = []): boolean => {
  return mutations.some((o: ItemUpdate) => o.save !== false)
}

const authorItems = {
  namespaced: true,
  state: new State(),

  getters: {
    initialized: (state: State): boolean => state.initialized,
    items: (state: State): ItemAuthor[] => state.items,
    banks: (state: State): Bank[] => state.banks,
    selectedBanks: (state: State): Id[] => state.selectedBanks,
    getItem: (state: State): ((itemId: Id) => ItemAuthor) => {
      return (itemId: Id): ItemAuthor => {
        return findItem(itemId, state.items)
      }
    },
    changed: (state: State): Id[] => state.changed,
    hasChange: (state: State): boolean => Boolean(state?.changed?.length),
    saving: (state: State): Id[] => state.saving,
    isSaving: (state: State): boolean => Boolean(state?.saving?.length),

    getItemPassageAff: (state: State): ((itemId) => ItemPassageAff) => {
      return (itemId: Id): ItemPassageAff => {
        const item = findItem(itemId, state.items)
        if (item && item.itemId == itemId) {
          return item.passageAff
        }
      }
    },
    getItemPassage: (state: State): ((itemId) => IPassageData) => {
      return (itemId: Id): IPassageData => {
        const item = findItem(itemId, state.items)
        if (item && item.itemId == itemId) {
          return item.passage
        }
      }
    },
    mode: (state: State): ItemAuthoringMode => state.mode,
    autosave: (state: State): boolean => state.autosave,

    /**
     * This is used for mapping tempory item ids (for new items)
     * and newly saved itemIds. If we mutate the data while the
     * item is being created we'll need to know what the created
     * itemId maps to in regargs to the temporay clientId.
     */
    newItemMap: (state: State): any[] => state.newItemMap,

    /**
     * Get item that is being edited
     * which happens to always be the first item
     * in the items collection by design.
     */
    getEditItem: (state: State): ItemAuthor => {
      return state.items?.[0] || null
    },
    menuTab: (state: State): MenuTab => state.menuTab,
    menuTabView: (state: State): String => state.menuTabView,
    loading: (state: State): boolean => state.loading,
    ogItemRevIdMap: (state: State): any => state.ogItemRevIdMap, // map of itemId/itemRevId when authoring loads
    itemsMutated: (state: State): any => state.itemsMutated, // keeps track of items that mutated remotely (saved to IBX)
    itemActions: (state: State): any => state.itemActions, // keeps track of actions taken on items
    hasErrors: (state: State): boolean => Object.keys(state.itemErrors).length > 0,
    itemErrors: (state: State): any => state.itemErrors, // keep track of item errors
    passageAffSaving: (state: State): boolean => state.passageAffSaving,
    active: (state: State): boolean => state.active,
    maxItems: (state: State): number => state.maxItems,
    itemLimit: (state: State): boolean => state.items.length >= state.maxItems,
    itemLimitMessage: (state: State): string => {
      return `You may only add a max of ${state.maxItems} items at a time.`
    },
    banksChanged: (state: State): boolean => state.banksChanged,
    saveAction: (state: State): ItemAuthorSaveAction => state.saveAction,
    inheritPassage: (state: State): boolean => state.inheritPassage,
    auxiliaryActions: (state: State): any => state.auxiliaryActions,
    itemRevUpdateOption: (state: State) => state.itemRevUpdateOption,
    hasNoStandard: (state: State): ((itemId: Id) => ItemAuthor) => {
      return (itemId: Id): any => {
        const item = findItem(itemId, state.items)
        if (item && item.itemId == itemId) {
          return item?.hasNoStandard
        }
      }
    },
  },

  mutations: {
    setInitialized: (state: State, value: boolean) => {
      state.initialized = value
    },
    setAutosave: (state: State, value: boolean = false) => {
      state.autosave = value
    },
    setItems: (state: State, data: IItemData[] = []) => {
      state.items = data.map((o: IItemData): ItemAuthor => {
        return new ItemAuthor(o)
      })
    },
    addItems: (state: State, data: IItemData[] = []) => {
      data.forEach((o: IItemData) => {
        state.items.push(new ItemAuthor(o))
      })
    },
    removeItems: (state: State, itemIds: Id[] = []) => {
      state.items = state.items.filter((o: ItemAuthor) => !itemIds.includes(o.itemId))
    },
    setBanks: (state: State, data: Bank[] = []) => {
      state.banks = data.map((o: Bank): Bank => {
        return {
          id: o.id,
          name: o.name,
        }
      })
    },
    updateItem: (state: State, { itemId, key, value }: ItemUpdate) => {
      const item = findItem(itemId, state.items)
      if (item && item.itemId == itemId) {
        item.set(key, value)
      }
    },
    onItemUpdate: (state: State, { itemId, itemData }: { itemId: Id; itemData: IItemData }) => {
      const item: ItemAuthor = findItem(itemId, state.items)
      if (item) {
        item.onItemUpdate(itemData)
      }
    },
    hydrateItem: (state: State, { itemId, itemData }: { itemId: Id; itemData: IItemData }) => {
      const item = findItem(itemId, state.items)
      if (item && item.itemId == itemId && itemData) {
        item.hydrate(itemData)
      }
    },
    /**
     * Hydrate item passage and re-initialze passage aff object
     */
    hydrateItemPassageAff: (state: State, { itemId, itemData }: { itemId: Id; itemData: IItemData }) => {
      const item = findItem(itemId, state.items)
      if (item && item.itemId == itemId) {
        if (!itemData) {
          item.set('passage', null)
          item.initPassageAff()
        } else {
          item.hydrate(itemData)
        }
      }
    },

    /**
     * Push/remove itemId to changed state
     * @param Id
     * @param changed
     */
    setChanged: (state: State, { Id, changed }: { Id: Id; changed: boolean }) => {
      if (changed) {
        Vue.set(state, 'changed', _.uniq([...state.changed, Id]))
      } else {
        Vue.set(
          state,
          'changed',
          state.changed.filter((v) => v != Id)
        )
      }
    },

    /**
     * Push/remove itemId to saving state
     * @param Id
     * @param saving
     */
    setSaving: (state: State, { Id, saving }: { Id: Id; saving: boolean }) => {
      if (saving) {
        Vue.set(state, 'saving', _.uniq([...state.saving, Id]))
      } else {
        Vue.set(
          state,
          'saving',
          state.saving.filter((v) => v != Id)
        )
      }
    },
    setItemPassageLink: (state: State, { itemId, passageRevId, remoteId, link }: ItemPassageAffAction) => {
      const item = findItem(itemId, state.items)

      if (item && item.itemId == itemId) {
        item.linkPassage({
          passageRevId,
          remoteId,
          link,
        })
      }
    },
    setMode: (state: State, value: ItemAuthoringMode) => {
      state.mode = value
    },
    setNewItemMap: (state: State, { clientId, itemId }: { clientId: Id; itemId: Id }) => {
      Vue.set(state.newItemMap, clientId, itemId)
    },
    setMenuTab: (state: State, value: MenuTab) => {
      state.menuTab = value
    },
    setMenuTabView: (state: State, value: String) => {
      state.menuTabView = value || null
    },
    reset: (state: State) => {
      state.newItemMap = {}
      state.items = []
      state.changed = []
      state.saving = []
      state.autosave = false
      state.mode = ItemAuthoringMode.CREATE
      state.menuTab = null
      state.ogItemRevIdMap = {}
      state.itemsMutated = {}
      state.itemActions = {}
      state.itemErrors = {}
      state.banksChanged = false
      state.saveAction = ItemAuthorSaveAction.DRAFT
      state.inheritPassage = true
      state.auxiliaryActions = {}
    },
    setLoading: (state: State, value: boolean) => {
      state.loading = value
    },

    /**
     * Keeps track of items that mutated remotely (saved mutations)
     */
    addItemsMutated: (state: State, { itemId, itemRevId }: ItemMutated) => {
      Vue.set(state.itemsMutated, itemId, itemRevId)
    },

    /**
     * Keeps track of actions taken on items (client-side)
     */
    trackItemActions: (state: State, { itemId, action = null }: ItemAction) => {
      const item = state.itemActions[itemId]
      if (item) item.push(action)
      else Vue.set(state.itemActions, itemId, [action])
    },

    /**
     * Keeps track of auxialliary actions (non-item actions)
     */
    trackAuxiliaryActions: (state: State, { key, value }: { key: string; value: any }) => {
      if (key) {
        Vue.set(state.auxiliaryActions, key, value || true)
      }
    },

    /**
     * Track item errors
     */
    trackItemError: (state: State, { itemId, error }: { itemId: Id; error?: any }) => {
      if (error) {
        Vue.set(state.itemErrors, itemId, error)
      } else {
        Vue.delete(state.itemErrors, itemId)
      }
    },

    /**
     * Remap item action for newId (used when creating items to map clientId to new itemId)
     */
    remapItemActions: (state: State, { oldId, newId }: { oldId: Id; newId: Id }) => {
      const item = state.itemActions[oldId]
      if (item) {
        Vue.set(state.itemActions, newId, item)
        Reflect.deleteProperty(state.itemActions, oldId)
      }
    },
    setPassageAffSaving: (state: State, value: boolean) => {
      state.passageAffSaving = Boolean(value)
    },
    setActive: (state: State, value: boolean) => {
      state.active = value
    },
    setBanksChanged: (state: State, value: boolean) => {
      state.banksChanged = value
    },
    setSaveAction: (state: State, value: ItemAuthorSaveAction) => {
      state.saveAction = value
    },
    setOgItemRevIdMap: (state: State, value: any) => {
      state.ogItemRevIdMap = value
    },
    setInheritPassage: (state: State, value: boolean) => {
      state.inheritPassage = value
    },
    setItemRevUpdateOption: (state: State, value: string = 'all') => {
      state.itemRevUpdateOption = value
      sessionStorage?.setItem('asmt-item-rev-update-option', value)
    },
    setNoStandard: (state: State, { itemId, data }: { itemId: Id; data: boolean }) => {
      const item: ItemAuthor = findItem(itemId, state.items)
      if (item) {
        item.hasNoStandard = data
      }
    },
  },

  actions: {
    /**
     * Initialize with/without autosave, init itemService
     * @param autosave autosave initial flag
     */
    init: async ({ commit, getters, rootGetters }) => {
      if (!getters.initialized) {
        commit('setInitialized', true)
        configs = ItemConfigs.getInstance()
        types = ItemTypes.getInstance()
      }
    },

    /**
     * Start item authoring.
     * Initializes item for editing or initialzed new item.
     * Sets autosave flag based on edit/draft modes
     * @param items items to edit (currently only one is allowed)
     */
    authorItems: async ({ commit, dispatch }, items: IItemData[] = []) => {
      commit('reset')
      const create = !items?.length // creation mode
      const edit = !create // edit mode
      const autosave = items?.[0]?.published != true // autosave mode (true only for draft)
      const validItems = edit ? items.slice(0, 1).map((o) => _.cloneDeep(o)) : [] // one item allowed for editing
      const mode = create ? ItemAuthoringMode.CREATE : ItemAuthoringMode.EDIT
      const ogItemsMap = edit
        ? validItems.reduce((m, o) => {
            if (o.itemId && o.itemRevId) m[o.itemId] = o.itemRevId
            return m
          }, {})
        : {}

      commit('setLoading', true)
      await dispatch('init')
      //await dispatch('fetchBanks')
      await dispatch('setAutosave', autosave)
      commit('setMode', mode)
      commit('setOgItemRevIdMap', ogItemsMap)
      commit('setItems', validItems)
      if (create) dispatch('createItems', { count: 1 })
    },

    /**
     * Start item authoring with duplicate item
     * @param contentConfig item config to duplicate
     * @param itemData item data to duplicate
     * @param linkPassage include item passage when duplicating item
     */
    authorDuplicateItem: async (
      { commit, dispatch, rootGetters },
      {
        contentConfig,
        itemData,
        linkPassage = true,
      }: { contentConfig: any; itemData: IItemData; linkPassage?: boolean }
    ) => {
      commit('reset')
      commit('setLoading', true)
      await dispatch('init')
      await dispatch('setAutosave', true)
      commit('setMode', ItemAuthoringMode.CREATE)
      commit('setOgItemRevIdMap', {})
      commit('setItems', [])

      // fetch allowed banks if not already fetched
      if (!rootGetters['itemAuthor/banksFetched']) {
        await dispatch('itemAuthor/fetchBanks', null, { root: true })
      }

      // item bank selections
      if (itemData?.meta?.bank?.items.length) {
        dispatch(
          'itemAuthor/setSelectedBanks',
          itemData.meta.bank.items.map((o) => o.id),
          { root: true }
        )
      }

      const options = {
        itemType: itemData.itemType,
        contentConfig: _.cloneDeep(contentConfig),
        passage: linkPassage ? itemData.passage : null,
      }
      const metaAttrs = _.cloneDeep(
        _.pick(itemData.meta, ['depth_of_knowledge', 'difficulty', 'standard', 'language', 'revised_blooms_taxonomy'])
      )
      if (!_.isEmpty(metaAttrs)) options['meta'] = metaAttrs

      dispatch('createItems', { count: 1, options })
    },

    /**
     * Set autosave flag
     * Subscribe/unsubscribe to autosaving
     */
    setAutosave: async ({ commit, dispatch }, value: boolean) => {
      commit('setAutosave', value)
    },

    /**
     * On item created update clientId:itemId mapping
     * then hydrate item with item data
     * @param clientId temporary itemId prior to the creation of the item.
     * @param itemId
     */
    onItemCreated: async (
      { commit },
      { clientId, itemId, itemData }: { clientId: Id; itemId: Id; itemData: IItemData }
    ): Promise<any> => {
      return new Promise((resolve) => {
        commit('setSaving', { Id: clientId, saving: false }) // remove temp item Id from saving state
        commit('setChanged', {
          Id: clientId,
          changed: false,
        })
        commit('setNewItemMap', { clientId, itemId })
        commit('hydrateItem', { itemId: clientId, itemData })
        commit('addItemsMutated', { itemId, itemRevId: itemData.itemRevId })
        commit('remapItemActions', { oldId: clientId, newId: itemId })
        commit('trackItemActions', { itemId, action: 'create' })
        resolve(null)
      })
    },

    /**
     * On item updated hydrate critical item properties with from response data
     * @param itemId
     */
    onItemUpdated: async (
      { commit, getters },
      { itemId, itemData }: { itemId: Id; itemData: IItemData }
    ): Promise<any> => {
      return new Promise((resolve) => {
        commit('setSaving', { Id: itemId, saving: false })
        commit('setChanged', {
          Id: itemId,
          changed: false,
        })
        commit('onItemUpdate', { itemId, itemData })
        commit('addItemsMutated', { itemId, itemRevId: itemData.itemRevId })
        resolve(null)
      })
    },

    /**
     * Fetch banks if not already stored
     */
    fetchBanks: async ({ commit, getters }) => {
      if (!getters.banks) {
        const { banks } = await IBXService.banks({ canEdit: 1 })
        commit('setBanks', banks)
      }
    },

    /**
     * Set items
     * Inject items via data
     * Fetch item from IBXService if not found in state
     */
    setItems: async ({ commit }, data: IItemData[] = []) => {
      commit('setItems', data)
    },

    /**
     * Update item. Sets item changed state if mutations is set to save.
     * @param mutations array of mutations
     */
    updateItem: async ({ commit }, mutations: ItemUpdate[] = []) => {
      if (mutations?.length) {
        if (saveMutation(mutations)) {
          mutations.forEach((o: ItemUpdate) => {
            // state changed state
            commit('setChanged', {
              Id: o.itemId,
              changed: true,
            })
            // track change taken
            commit('trackItemActions', {
              itemId: o.itemId,
              action: o.key,
            })
          })
        }
        mutations.forEach((itemUpdate) => commit('updateItem', itemUpdate))
      }
    },

    updateItemMeta: async ({ commit }, mutations: ItemUpdate[]) => {
      if (mutations?.length && saveMutation(mutations)) {
        mutations.forEach((o) => {
          commit('setChanged', {
            Id: o.itemId,
            changed: true,
          })
        })
      }
    },

    /**
     * Track that item was deleted
     */
    deleteItem: async ({ commit }, itemId: Id) => {
      commit('trackItemActions', {
        itemId,
        action: 'delete',
      })
    },

    /**
     * Called when data has saved or save error
     * DO WE NEED THIS?
     */
    onSaved: async ({ commit }, { success, data, errors }: { success: boolean; data?: any[]; errors?: any[] }) => {
      if (success) {
        EventBus.$emit(Events.AUTHOR_ITEMS_SAVED, data)
      } else {
        EventBus.$emit(Events.AUTHOR_ITEMS_SAVE_ERROR, errors)
      }
    },

    /**
     * Remove item from state
     * @param itemIds items to remove
     */
    removeItems: async ({ commit }, itemIds: Id[] = []) => {
      commit('removeItems', itemIds)

      // track delete action for all removed items
      itemIds.forEach((itemId) => {
        commit('trackItemActions', {
          itemId,
          action: 'delete',
        })
      })
    },

    /**
     * Create items
     * This will NOT save items, it will simply add them to the UI for editing
     * @param count number of items to create
     * @param options item options
     */
    createItems: async (
      { commit, getters },
      {
        count = 1,
        options = {},
        passageAff,
      }: {
        count: number
        options?: any
        passageAff?: ItemPassageAff
      }
    ) => {
      const lastNewIdex = getters.items.reduce(
        (max: number, o: ItemAuthor) => (o.isNew ? Math.max(max, o.newIndex) : max),
        0
      )
      const itemType = options?.itemType || types.default.id
      const contentConfig = options?.contentConfig || _.cloneDeep(configs.getContentConfig(itemType))
      const configSettings = options?.configSettings || _.cloneDeep(configs.getSettings(itemType))

      const items = Array.from(Array(count), (_, i: number) => {
        const index = lastNewIdex + i + 1
        const itemData: IItemData = {
          newIndex: index,
          itemId: uniqueId(), // temporary clientId prior to saving
          itemType: itemType,
          itemRevId: null,
          locked: false,
          createdAt: null,
          contentConfig: contentConfig,
          configSettings: configSettings,
          inUse: false,
          externalIds: [],
          legacyIds: [],
          meta: {},
          omittedAt: null,
          passage: null,
          published: null,
          revisionId: null,
          remoteId: null,
          remoteIdName: null,
          remoteIdVersioned: null,
          source: null,
          stem: null,
          teacherInstructions: null,
          updatedAt: null,
          user: {},
          version: null,
        }
        return Object.assign({}, itemData, options)
      })
      commit('addItems', items)
    },

    /**
     * Clears all items
     */
    resetItems: async ({ commit }) => {
      commit('setItems', [])
    },

    /**
     * Reset state to defaults
     */
    reset: ({ commit }) => {
      commit('reset')
    },

    /**
     * Set Item/Passage link
     */
    setItemPassageLink: async ({ commit, getters }, affAction: ItemPassageAffAction) => {
      const item: ItemAuthor = findItem(affAction.itemId, getters.items)
      commit('setItemPassageLink', affAction)

      if (item) {
        // state changed state
        commit('setChanged', {
          Id: affAction.itemId,
          changed: Boolean(item.passageAffMutated),
        })

        // track change taken
        commit('trackItemActions', {
          itemId: affAction.itemId,
          action: affAction.link ? 'passage-link' : 'passage-unlink',
        })
      }
    },

    /**
     * On item passage aff updated.
     * Rehydrate passage aff
     * @param itemId
     * @param itemData
     */
    onItemPassageUpdated: ({ commit }, { itemId, itemData }: { itemId: Id; itemData: IItemData }) => {
      commit('setSaving', { Id: itemId, saving: false })
      commit('setChanged', {
        Id: itemId,
        changed: false,
      })
      commit('hydrateItemPassageAff', { itemId, itemData })
      commit('addItemsMutated', { itemId, itemRevId: itemData.itemRevId })
    },

    /**
     * Keep track on auxiliary actions
     * data: { key: string, value: any}
     */
    onAuxiliaryAction: ({ commit }, data: { [key: string]: any } = {}) => {
      Object.keys(data).forEach((key: string) => {
        commit('trackAuxiliaryActions', {
          key,
          value: data[key],
        })
      })
    },
    setNoStandard: ({ commit }, { itemId, data }) => {
      commit('setNoStandard', { itemId, data })
    },
  },
} as StoreOptions<State>

export { authorItems }
